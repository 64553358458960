type Prop = {
  openStoreInfo: boolean
  handleOpenStoreInfo: (a: boolean) => void
}

export const StoreInfo = ({ openStoreInfo, handleOpenStoreInfo }: Prop) => {
  return (
    <div className={`modal-lightbox ${openStoreInfo ? 'open' : ''}`}>
      <div className="lightbox md:!max-w-[calc((100vw/2)-120px)] md:!translate-x-[unset] md:!left-0 md:mr-15 md:ml-auto lg:!max-w-[calc((100vw/3)-80px)] lg:mr-10 xl:!w-[calc((100vw/3)-120px)] xl:mr-15 3xl:!w-[calc((1600px/3)-100px)] 3xl:mr-[calc(((100vw-1600px)/2)+80px)]">
        <div className="mx-auto h-screen md:h-[unset] rounded-lg bg-white">
          <div className="p-6">
            Attendere conferma via email del tempo di ritiro
          </div>
        </div>
      </div>
      <div
        className={`modal-overlay ${
          openStoreInfo ? 'block opacity-100' : 'hidden opacity-0'
        }`}
        onClick={() => handleOpenStoreInfo(false)}
      ></div>
    </div>
  )
}
