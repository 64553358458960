import { useCart, useSession } from '@faststore/sdk'
import { lazy, Suspense, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import GreenCheck from 'src/components/icons/GreenCheck'
import InfoIcon from 'src/components/icons/InfoIcon'
import { useStoreContext } from 'src/contexts/store-context'
import { useDeliveryEstimate } from 'src/sdk/store/useDeliveryEstimate'
import { DeliveryEstimateCaseEnum } from '../Store/typings'

const DeliveryDetailsModal = lazy(() => import('./DeliveryDetailsModal'))

type Props = {
  sku: string
}

export const DeliveryEstimate = ({ sku }: Props) => {
  const [deliveryModalOpen, setDeliveryModalOpen] = useState<'open' | 'close'>(
    'close'
  )
  const { person } = useSession()
  const { id } = useCart()

  const { deliveryEstimate, setDeliveryEstimate } = useStoreContext()

  const scrollBarWidth = window?.innerWidth - document?.body?.clientWidth

  const handleModal = (action: 'open' | 'close') => {
    action === 'open'
      ? (document.body.classList.add('no-scroll'),
        (document.body.style.paddingRight = `${scrollBarWidth}px`))
      : (document.body.classList.remove('no-scroll'),
        (document.body.style.paddingRight = '0px'))

    setDeliveryModalOpen(action)
  }

  useEffect(() => {
    useDeliveryEstimate({
      cartId: id,
      email: person?.email,
      sku: sku,
    }).then((res: any) => setDeliveryEstimate(res))
  }, [person?.email, id])

  return deliveryEstimate ? (
    <div className="flex flex-row gap-1">
      <span>
        <GreenCheck />
      </span>
      <span className="text-black font-medium text-sm">
        {`${
          deliveryEstimate.type === DeliveryEstimateCaseEnum.Range
            ? `Disponibile alla consegna a domicilio tra il ${deliveryEstimate.value?.from} e il  ${deliveryEstimate.value?.to}`
            : `Consegna a domicilio prevista per il ${deliveryEstimate.value}`
        }`}{' '}
        <span className="inline-flex align-text-top ml-[1px]">
          <button
            onClick={() => handleModal('open')}
            className="cursor-pointer bg-transparent flex items-center justify-center p-0"
          >
            <InfoIcon />
          </button>
        </span>
        {deliveryModalOpen === 'open' &&
          createPortal(
            <Suspense fallback={null}>
              <DeliveryDetailsModal closeModal={() => handleModal('close')} />
            </Suspense>,
            document.body
          )}
      </span>
    </div>
  ) : (
    <div className="animate-pulse flex space-x-4">
      <div className="flex-1 space-y-4 py-1">
        <div className="h-6 bg-gray-500 rounded" />
      </div>
    </div>
  )
}
