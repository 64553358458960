import { gql } from '@faststore/graphql-utils'
import { useSession } from '@faststore/sdk'
import type {
  ProductPageQueryQuery,
  ProductPageQueryQueryVariables,
  ServerProductPageQueryQuery,
} from '@generated/graphql'
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import {
  BreadcrumbJsonLd,
  GatsbySeo,
  ProductJsonLd,
} from 'gatsby-plugin-next-seo'
import { useEffect } from 'react'
// import LazyRenderCMS from 'src/components/LazyRenderCMS'
import RenderCMS from 'src/components/RenderCMS'
import { RenderClient } from 'src/components/RenderClient'
import NewProductDetails from 'src/components/sections/NewPDP/NewProductDetails'
import { useScrollEvent } from 'src/sdk/analytics/hooks/useScrollEvent'
import { useUserEvent } from 'src/sdk/analytics/hooks/useUserEvent'
import { mark } from 'src/sdk/tests/mark'
// import 'src/styles/pages/new-pdp.css'

export type Props = PageProps<
  ProductPageQueryQuery,
  ProductPageQueryQueryVariables,
  unknown,
  ServerProductPageQueryQuery
> & {
  slug: string
  availableProducts: {
    availableQuantity: string | null
    videos: string[] | null
  }
}

type OfferAvailability =
  | 'Discontinued'
  | 'InStock'
  | 'InStoreOnly'
  | 'LimitedAvailability'
  | 'OnlineOnly'
  | 'OutOfStock'
  | 'PreOrder'
  | 'PreSale'
  | 'SoldOut'

type ItemCondition =
  | 'DamagedCondition'
  | 'NewCondition'
  | 'RefurbishedCondition'
  | 'UsedCondition'

function Page(props: Props) {
  const { locale, currency } = useSession()

  const {
    data: { site, cmsPdp },
    serverData,
    slug,
  } = props

  const {
    product,
    product: { seo },
  } = serverData

  const description = product.metaDescription

  useUserEvent('Product Detail Page')

  const title = product?.seo.title ?? site?.siteMetadata?.title ?? ''
  const canonical = `${site?.siteMetadata?.siteUrl}${seo.canonical}`
  const productDescription = product.description
    .replace(/(<([^>]+)>|&rsquo;|&Igrave;)/gi, '')
    .replace(/&nbsp;/g, ' ')

  const unitMultiplier = product.productSpecifications?.unitMultiplier ?? 0

  // Value of price to be inserted in the SEO metadata, in order to drive the proper value for the Google Shopping feed
  const multipliedPrice = unitMultiplier
    ? product.offers.lowPrice * unitMultiplier
    : product.offers.lowPrice

  useEffect(() => {
    const mainParentElement = document.getElementById('main-parent-element')

    if (mainParentElement != null) {
      mainParentElement.className = ''
    }

    const mdBreakPoint = 768
    const addToCartCol = document.getElementsByClassName(
      'add-to-cart-col-JS'
    )[0] as HTMLElement

    const contentContainerCol = document.getElementsByClassName(
      'main-content-container-col-JS'
    )[0] as HTMLElement

    const contentCol = document.getElementsByClassName(
      'main-content-col-JS'
    )[0] as HTMLElement

    const fixHeight = () => {
      const windowWidth = window.innerWidth
      const addToCartColHeightScroll = addToCartCol?.scrollHeight
      const contentColHeight = contentCol?.offsetHeight

      if (windowWidth && windowWidth < mdBreakPoint && contentContainerCol) {
        contentContainerCol.style.height = 'auto'
      } else if (addToCartColHeightScroll !== contentColHeight) {
        if (addToCartColHeightScroll > contentColHeight) {
          contentContainerCol.style.height = `${addToCartColHeightScroll}px`
        } else {
          contentContainerCol.style.height = 'auto'
        }
      }
    }

    window.addEventListener('resize', fixHeight)
    window.addEventListener('scroll', useScrollEvent)
    fixHeight()

    return () => {
      window.removeEventListener('resize', fixHeight)
      window.removeEventListener('scroll', useScrollEvent)
    }
  }, [])

  if (serverData === null) {
    return null
  }

  return (
    <div className="min-h-screen">
      <RenderClient>
        <div id="new-pdp">
          {/* SEO */}
          <GatsbySeo
            title={title}
            titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
            description={description}
            canonical={canonical}
            language={locale}
            openGraph={{
              type: 'og:product',
              url: `${site?.siteMetadata?.siteUrl}${slug}`,
              title,
              description,
              images: product.image.map((img) => ({
                url: img.url,
                alt: img.alternateName,
              })),
            }}
            metaTags={[
              {
                property: 'product:price:amount',
                content: multipliedPrice.toFixed(2).toString() ?? undefined,
              },
              {
                property: 'product:price:currency',
                content: currency.code,
              },
            ]}
          />
          <BreadcrumbJsonLd
            itemListElements={product.breadcrumbList.itemListElement ?? []}
          />

          <ProductJsonLd
            name={title}
            description={productDescription}
            brand={product.brand.name}
            sku={product.sku}
            images={product.image.map((img) => img.url)} // Somehow, Google does not understand this valid Schema.org schema, so we need to do conversions
            offers={{
              availability: product.offers.offers[0].availability
                .split('/')
                .pop() as OfferAvailability,
              price: multipliedPrice.toFixed(2).toString(),
              priceValidUntil: product.offers.offers[0].priceValidUntil,
              priceCurrency: product.offers.offers[0].priceCurrency,
              itemCondition: product.offers.offers[0].itemCondition
                .split('/')
                .pop() as ItemCondition,
              seller: {
                name: 'Arcaplanet',
              },
            }}
          />

          {/*
      WARNING: Do not import or render components from any
      other folder than '../components/sections' in here.

      This is necessary to keep the integration with the CMS
      easy and consistent, enabling the change and reorder
      of elements on this page.

      If needed, wrap your component in a <Section /> component
      (not the HTML tag) before rendering it here.
    */}

          <NewProductDetails product={product} />

          {/* RenderCMS splices the first 3 elements for render without lazyloading */}
          <RenderCMS sections={cmsPdp?.sections} />
          {/* LazyRenderCMS render all the elements with lazyloading unless the 3 first */}
          {/* <LazyRenderCMS sections={cmsPdp?.sections} /> */}
        </div>
      </RenderClient>
    </div>
  )
}

export const querySSG = graphql`
  query ProductPageQuery {
    site {
      siteMetadata {
        title
        description
        titleTemplate
        siteUrl
      }
    }
    cmsPdp {
      sections {
        id
        name
        data
      }
    }
  }
`

export const querySSR = gql`
  query ServerProductPageQuery($slug: String!) {
    product(locator: [{ key: "slug", value: $slug }]) {
      id: productID
      slug

      seo {
        title
        description
        canonical
      }

      brand {
        name
      }

      slug
      sku
      gtin
      name
      description
      metaDescription
      fixedPrices {
        tradePolicyId
        value
        listPrice
        minQuantity
        dateRange {
          from
          to
        }
      }

      breadcrumbList {
        itemListElement {
          item
          name
          position
        }
      }

      image {
        url
        alternateName
      }

      offers {
        lowPrice
        highPrice
        priceCurrency
        offers {
          availability
          price
          priceValidUntil
          priceCurrency
          itemCondition
          seller {
            identifier
          }
        }
      }

      ...ProductDetailsFragment_product
    }
  }
`

export const getServerData = async ({
  params: { slug },
}: {
  params: Record<string, string>
}) => {
  const ONE_YEAR_CACHE = `s-maxage=31536000, stale-while-revalidate`
  const { isNotFoundError } = await import('@faststore/api')
  const { execute } = await import('src/server')
  const { data, errors = [] } = await execute({
    operationName: querySSR,
    variables: { slug },
  })

  const notFound = errors.find(isNotFoundError)

  if (notFound) {
    return {
      status: 301,
      props: null,
      headers: {
        'cache-control': ONE_YEAR_CACHE,
        location: `/404`,
      },
    }
  }

  if (errors.length > 0) {
    throw errors[0]
  }

  return {
    status: 200,
    props: data ?? {},
    headers: {
      'cache-control': ONE_YEAR_CACHE,
    },
  }
}

Page.displayName = 'Page'

export default mark(Page)
