import { Price } from '@faststore/ui'
import { memo, useEffect } from 'react'
import { useBuyButtonPDP } from 'src/sdk/cart/useBuyButtonPDP'
import { useFormattedPrice } from 'src/sdk/product/useFormattedPrice'

import type { ExtendedProductDetails } from './typings'

type StickyCartProps = {
  product: ExtendedProductDetails
  priceInfo: {
    verifiedPriceToShow: number
    verifiedListPriceToShow: number
    discount: number
  }
  handleOpenCloseAddToCart: (x: boolean) => void
  setIsAddedFromSticky: React.Dispatch<React.SetStateAction<boolean>>
}

const StickyCart = ({
  product,
  priceInfo,
  handleOpenCloseAddToCart,
  setIsAddedFromSticky,
}: StickyCartProps) => {
  const buyProps = useBuyButtonPDP({
    item: {
      id: product.id,
      price: product?.offers?.offers[0]?.price,
      listPrice: product?.offers?.offers[0]?.listPrice,
      seller: product?.offers?.offers[0]?.seller,
      quantity: 1,
      itemOffered: {
        sku: product.sku,
        name: product.name,
        breadcrumbList: product.breadcrumbList,
        gtin: product.gtin,
        image: product.image,
        brand: product.brand,
        isVariantOf: product.isVariantOf,
        additionalProperty: product.additionalProperty,
      },
    },
    boxType: 'footer sticky',
    recurringOrder: false,
  })

  const handleAddToCartClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsAddedFromSticky(true)
    buyProps.onClick(e)
    handleOpenCloseAddToCart(true)
  }

  const offSetBottom = 550
  const elementIsVisibleInViewport = (el: any, partiallyVisible = true) => {
    const { top, left, bottom, right } = el?.getBoundingClientRect()
    const { innerHeight, innerWidth } = window

    return partiallyVisible
      ? ((top > 0 && top < innerHeight) ||
          (bottom > 0 && bottom < innerHeight)) &&
          ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
      : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth
  }

  useEffect(() => {
    const calculateDistanceToBottom = (scrollPosition: number) => {
      const windowSize = window.innerHeight
      const bodyHeight = document.body.offsetHeight

      return Math.max(bodyHeight - (scrollPosition + windowSize), 0)
    }

    const isSticky = () => {
      const addToCartSticky =
        document?.getElementsByClassName('add-to-cart-sticky')[0]

      const currentScroll = window.scrollY

      if (calculateDistanceToBottom(currentScroll) > offSetBottom) {
        const stickyTarget = document.querySelector('.sticky-target-JS') ?? null

        if (elementIsVisibleInViewport(stickyTarget)) {
          if (addToCartSticky.classList.contains('is-sticky')) {
            addToCartSticky.classList.remove('is-sticky')
          }
        } else if (!addToCartSticky.classList.contains('is-sticky')) {
          addToCartSticky.classList.add('is-sticky')
        }
      } else if (addToCartSticky.classList.contains('is-sticky')) {
        addToCartSticky.classList.remove('is-sticky')
      }
    }

    window.addEventListener('resize', isSticky)
    window.addEventListener('scroll', isSticky)

    return () => {
      window.removeEventListener('resize', isSticky)
      window.removeEventListener('scroll', isSticky)
    }
  }, [])

  return (
    <div className="add-to-cart-sticky">
      <div className="py-4 px-6">
        <div className="relative w-full xl:max-w-7xl xl:mx-auto">
          <div className="w-full flex flex-col md:flex-row justify-between items-center gap-2 md:gap-15 xl:gap-20">
            <div className="">
              <div className="absolute top-0 left-0 h-[42px] w-[42px] md:h-[72px] md:w-[72px] xl:h-[82px] xl:w-[82px]">
                <img
                  className="object-contain max-h-full max-w-full m-auto"
                  src={product?.image[0]?.url}
                  alt="product"
                />
              </div>
              <div className="relative flex flex-col justify-center gap-2 ml-[50px] md:ml-20 xl:ml-24 min-h-[42px] md:min-h-[72px] xl:min-h-[unset] w-[calc(100%-50px)] md:w-[calc(100%-80px)] xl:w-[calc(100%-96px)] md:pb-10 xl:pb-3">
                <h2 className="font-normal leading-[130%] line-clamp-2">
                  {product.isVariantOf.name}
                </h2>
              </div>
              <div className="hidden xl:block xl:ml-[96px] xl:w-[calc(100%-96px)]">
                <div className="w-full flex gap-5 text-sm text-[#0A0A0A] justify-between">
                  <a href="#descrizione" style={{ textDecoration: 'none' }}>
                    Descrizione
                  </a>{' '}
                  <a href="#ingredienti" style={{ textDecoration: 'none' }}>
                    Ingredienti
                  </a>{' '}
                  <a href="#dosaggio" style={{ textDecoration: 'none' }}>
                    Dosaggio
                  </a>{' '}
                  <a href="#recensioni" style={{ textDecoration: 'none' }}>
                    Recensioni
                  </a>
                </div>
              </div>
            </div>
            <div className="w-full md:max-w-[265px] xl:max-w-[375px]">
              <div className="w-full flex justify-between items-end mb-4 md:mb-1">
                <div className="md:absolute md:bottom-0 md:left-[80px] xl:relative xl:left-0 xl:bottom-0">
                  <div className="flex flex-wrap items-center mt-2 md:mt-1 lg:mt-0 lg:ml-0">
                    <div className="mr-2 text-sm xl:hidden">
                      Ordine singolo:
                    </div>
                    <div className="flex items-center gap-1">
                      <Price
                        value={priceInfo.verifiedPriceToShow}
                        formatter={useFormattedPrice}
                        testId="price"
                        data-value={priceInfo.verifiedPriceToShow}
                        variant="spot"
                        className="block text-xl font-normal leading-[130%]"
                      />
                      {priceInfo.verifiedPriceToShow && (
                        <Price
                          value={priceInfo.verifiedListPriceToShow}
                          formatter={useFormattedPrice}
                          testId="list-price"
                          data-value={priceInfo.verifiedListPriceToShow}
                          variant="listing"
                          className="block text-sm relative before:absolute before:content[''] before:top-[50%] before:translate-y-[10%] before:w-full before:h-[1px] before:z-[2] before:bg-black leading-[130%]"
                        />
                      )}
                      {/* <span className="block text-sm text-[#CE3535] leading-[130%]">
                        (-{Math.round(priceInfo.discount)}%)
                      </span> */}
                    </div>
                    <div className="ml-2 text-[rgba(20,24,31,0.92)] md:hidden">
                      (x1)
                    </div>
                  </div>
                </div>
                <div className="hidden md:flex gap-2 text-sm mb-[2px]">
                  <div className="">X</div>
                  <div className="">1</div>
                </div>
              </div>
              <div className="w-full">
                <button
                  className="btn btn-primary w-full cursor-pointer"
                  onClick={(e) => handleAddToCartClick(e)}
                >
                  Aggiungi al carrello
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(StickyCart)
