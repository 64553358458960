import { IconProps } from './IconsTypes'

const InfoIcon = ({
  width = 16,
  height = 16,
  color = '#0F0F13',
  viewBox = '0 0 16 16',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00065 15.1667C4.04865 15.1667 0.833984 11.952 0.833984 8.00001C0.833984 4.04801 4.04865 0.833344 8.00065 0.833344C11.9527 0.833344 15.1673 4.04801 15.1673 8.00001C15.1673 11.952 11.9527 15.1667 8.00065 15.1667ZM8.00065 1.83334C4.59998 1.83334 1.83398 4.59934 1.83398 8.00001C1.83398 11.4007 4.59998 14.1667 8.00065 14.1667C11.4013 14.1667 14.1673 11.4007 14.1673 8.00001C14.1673 4.59934 11.4013 1.83334 8.00065 1.83334ZM8.50065 11V7.95265C8.50065 7.67665 8.27665 7.45265 8.00065 7.45265C7.72465 7.45265 7.50065 7.67665 7.50065 7.95265V11C7.50065 11.276 7.72465 11.5 8.00065 11.5C8.27665 11.5 8.50065 11.276 8.50065 11ZM8.68066 5.66668C8.68066 5.29868 8.38266 5.00001 8.014 5.00001H8.00732C7.63932 5.00001 7.34391 5.29868 7.34391 5.66668C7.34391 6.03468 7.646 6.33334 8.014 6.33334C8.382 6.33334 8.68066 6.03468 8.68066 5.66668Z"
        fill={color}
      />
    </svg>
  )
}

export default InfoIcon
